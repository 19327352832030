import { CompleteProduct, ProductEventsListInterface } from "../../../apis/types/generate-product";
import { produce } from "immer";
import { ProductDetailsActionTypes, ProductsDetailsReducerActionType } from "./productDetails.actions";

export interface State {
  completeProduct: CompleteProduct;
  productEventHistory: ProductEventsListInterface
}

const initialState: State = {
  completeProduct: {} as CompleteProduct,
  productEventHistory: {
    today: [],
    yesterday: [],
    earlier: []
  }
};

const productDetailsReducer = produce((state: State = initialState, action: ProductsDetailsReducerActionType) => {
  switch (action.type) {
    case ProductDetailsActionTypes.setCompleteProduct:
      state.completeProduct = action.payload;
      return state;
    case ProductDetailsActionTypes.setProductEventHistory:
      state.productEventHistory = action.payload;
      return state;
    default:
      return state;
  }
});

export default productDetailsReducer;
