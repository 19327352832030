import React, { ChangeEvent, DragEvent, useEffect, useState } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box } from "@mui/material";

import classes from "./UploadBox.module.css";
import { checkFileSizeLessThan10Mbs, validMediaFileTypes } from "../../../utils/helpers/common";
import { toast } from "react-toastify";
import MButton from "../../Atoms/MButton";

const UploadBox = (props: any) => {
  const { handleSaveFile, isClear = 0, clearSelectedFile } = props;
  const [selectedFile, setSelectedFile] = useState<any>(null);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isClear > 0) {
      setSelectedFile(null);
    }
  }, [isClear]);
  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles && droppedFiles.length > 0) {
      saveFile(droppedFiles[0]);
    }
  };
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const droppedFiles = event.target.files;
    if (droppedFiles && droppedFiles.length > 0) {
      saveFile(droppedFiles[0]);
    }
  };

  const saveFile = (uploadedFile: File) => {
    const file = uploadedFile;
    const fileType = file.type;

    const isValidSize = checkFileSizeLessThan10Mbs(file?.size);
    const isValidFileType = validMediaFileTypes(fileType);
    if (isValidSize && isValidFileType) {
      handleSaveFile(uploadedFile);
      setSelectedFile(uploadedFile);
    } else {
      let errorMsg = "Please attach PNG or JPG file with a max size of 5MB.";
      toast.error(errorMsg, { autoClose: 3000 });
    }
  };

  const handleClear = () => {
    setSelectedFile(null);
    clearSelectedFile(null);
  };

  return (
    <>
      {selectedFile ? (
        <Box component={"div"} className={classes.UploadContainer}>
          <Box component={"div"} py={"10px"} sx={{ fontSize: "16px", lineHeight: "24px" }}>
            {selectedFile?.name}
          </Box>
          <MButton onClick={handleClear} size={"small"} variant={"text"} color={"primary"}>
            Clear
          </MButton>
        </Box>
      ) : (
        <Box
          component={"div"}
          className={classes.UploadContainer}
          onClick={() => {
            document.getElementById("fileInput")?.click();
          }}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
        >
          <UploadFileIcon color="primary" />
          <Box component={"div"} py={"10px"} sx={{ fontSize: "16px", lineHeight: "24px" }}>
            <span className={classes.ClickToUpload}>Click to upload</span> or drag and drop (optional)
          </Box>
          <Box component={"div"} className={classes.CsvOrXLS}>
            JPG or PNG (max. 5MB)
          </Box>
          <input id="fileInput" type="file" style={{ display: "none" }} onChange={handleFileChange} />
        </Box>
      )}
    </>
  );
};

export default UploadBox;
